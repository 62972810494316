<template>
  <core-section id="lets-talk">
    <core-heading>Let's talk.</core-heading>

    <v-col
      cols="12"
      md="5"
      class="mb-4 pr-md-5"
    >
      <core-subheading class="text-uppercase">
        What can I do for you?
      </core-subheading>

      <core-text class="mb-5">
        NOTE: Support requests related to myGMRS or RepeaterFinder must be submitted here: <a href="https://mygmrs.com/contact">myGMRS Contact Form</a>
      </core-text>

      <core-subheading class="text-uppercase mb-3">
        Send me a Message
      </core-subheading>

      <v-form
        ref="form"
        v-model="formValid"
      >
        <v-text-field
          v-model="name"
          autocomplete="name"
          :rules="nameRules"
          required
          solo-inverted
          flat
          label="Name"
        />

        <v-text-field
          v-model="email"
          autocomplete="email"
          :rules="emailRules"
          required
          solo-inverted
          flat
          label="Email"
        />

        <!-- <v-text-field
          v-model="subject"
          solo-inverted
          flat
          label="Subject"
        /> -->

        <v-textarea
          v-model="message"
          :rules="messageRules"
          required
          solo-inverted
          flat
          label="Message"
        />

        <v-alert
          v-if="error"
          type="error"
          icon="mdi-alert-circle"
        >
          Sorry! An error occurred while sending your message: {{ error }}
        </v-alert>

        <v-alert
          v-else-if="sentSuccessfully"
          type="success"
          icon="mdi-check-circle"
        >
          Thank you, your message has been sent successfully!
        </v-alert>

        <v-btn
          class="ma-0"
          color="primary"
          :disabled="!formValid"
          @click="sendContactForm"
        >
          Contact Me
        </v-btn>
      </v-form>
    </v-col>

    <v-col
      cols="12"
      md="4"
      class="text-left"
    >
      <core-subheading class="text-uppercase">
        Social Contacts
      </core-subheading>

      <social-contacts />

      <v-list
        class="transparent"
        three-line
      >
        <v-list-item v-if="schema.basics.location.address || schema.basics.location.city || schema.basics.location.postalCode">
          <v-list-item-avatar>
            <v-icon
              color="primary"
              size="36"
            >
              mdi-map-marker
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Mailing Address
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ schema.basics.location.address }}<br>
              {{ schema.basics.location.city }}, {{ schema.basics.location.postalCode }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="schema.basics.email">
          <v-list-item-avatar>
            <v-icon
              size="36"
              color="primary"
            >
              mdi-email
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Email
            </v-list-item-title>
            <v-list-item-subtitle v-text="schema.basics.email" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="schema.basics.phone">
          <v-list-item-avatar>
            <v-icon
              size="36"
              color="primary"
            >
              mdi-phone
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Phone
            </v-list-item-title>
            <v-list-item-subtitle v-text="schema.basics.phone" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <!-- <v-img
      :src="require('@/assets/john-alt.png')"
      contain
      max-width="30vw"
      style="bottom: -20px; right: 0; position: absolute;"
      width="100%"
    /> -->
  </core-section>
</template>

<script>
  import axios from 'axios'

  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'LetsTalk',

    components: {
      SocialContacts: () => import('@/components/SocialContacts'),
    },

    data: () => ({
      formValid: false,
      sentSuccessfully: false,
      name: null,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      email: null,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid',
      ],
      // subject: null,
      // subjectRules: [
      //   v => !!v || 'Subject is required',
      // ],
      message: null,
      messageRules: [
        v => !!v || 'Message is required',
      ],
    }),

    computed: {
      ...mapState('app', ['schema']),
    },

    methods: {
      async sendContactForm () {
        try {
          this.sentSuccessfully = false
          this.error = null

          if (!this.name || !this.email || !this.message) {
            console.error('Missing required information')
          } else {
            // @todo use a custom domain, load api key dynamically
            const response = await axios.post('https://api.richdunajewski.com/send', {
              subject: `New Message from ${this.name} - RichDunajewski.com`,
              message: `<div><strong>Name:&nbsp;&nbsp;</strong>${this.name}<br><strong>Email:&nbsp;&nbsp;</strong>${this.email}<br><strong>Message:</strong><br><br>${this.message}</div>`,
            }, {
              headers: {
                'x-api-key': 'sVLoQyjw6X1IwFpSrLSta5wGdfDzTMOM4thL6prd',
              },
            })

            switch (response.status) {
              case 200:
              case 201:
              case 204:
                this.name = null
                this.email = null
                this.message = null
                this.sentSuccessfully = true
                this.$refs.form.resetValidation()

                setTimeout(() => {
                  this.sentSuccessfully = false
                }, 10000)
                break
              default:
                console.error(response.status, response.data)
                this.error = response.data

                setTimeout(() => {
                  this.error = null
                }, 10000)
            }
          }
        } catch (err) {
          console.error(err)
          this.error = err

          setTimeout(() => {
            this.error = null
          }, 10000)
        }
      },
    },
  }
</script>
